import { useRef } from 'react';
import PropTypes from 'prop-types';
import useIsomorphicLayoutEffect from '@utility/useIsomorphicLayoutEffect';

const isBrowser = typeof window !== 'undefined';

function getScrollPosition({ element, useWindow }) {
  if (!isBrowser) return 0;

  const target = element ? element.current : document.body;

  return useWindow ? window.scrollY : target.scrollTop;
}

export default function useScrollPosition(
  effect,
  reRender,
  element,
  useWindow,
  wait,
) {
  const position = useRef(getScrollPosition({ useWindow }));

  let throttleTimeout = null;

  const callBack = () => {
    const currPos = getScrollPosition({ element, useWindow });
    effect({ prevPos: position.current, currPos });
    position.current = currPos;
    throttleTimeout = null;
  };

  useIsomorphicLayoutEffect(() => {
    if (!isBrowser) {
      return;
    }
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(
            window.requestAnimationFrame(callBack),
            wait,
          );
        }
      } else {
        window.requestAnimationFrame(callBack);
      }
    };

    const target = useWindow ? window : element.current;
    target.addEventListener('scroll', handleScroll, { passive: true });

    return () => target.removeEventListener('scroll', handleScroll);
  }, reRender);
}

useScrollPosition.propTypes = {
  effect: PropTypes.func, // Changes to be made during scroll event
  reRender: PropTypes.arrayOf(PropTypes.string), // state or props that should
  // tell useScrollPosition to re-render
  element: PropTypes.node, // element to detect
  useWindow: PropTypes.bool, // use for window scroll detection rather than el or body
  wait: PropTypes.number, // throttle in ms
};

useScrollPosition.defaultProps = {
  reRender: [],
  element: false,
  useWindow: true,
  wait: null,
};
