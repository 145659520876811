import React from 'react';
import FirebaseContext from '@context/FirebaseContext';

/**
 * withFirebase - HOC which retrieves
 * the firebase instance from the firebase context
 * and passes it to the components which require firebase
 * @param {class} Component
 * @return {class} Component withFirebase
 */

const withFirebase = (Component) => (props) =>
  (
    <FirebaseContext.Consumer>
      {(firebase) => <Component {...props} firebase={firebase} />}
    </FirebaseContext.Consumer>
  );

export default withFirebase;
