import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import * as styles from './styles';

const SnipcartCounter = ({ cartState }) => {
  return (
    <div
      id="cartCounter"
      className={cn(styles.snipcartCounter, {
        [styles.isActive]: cartState?.hasItems,
      })}
    >
      <span>{cartState?.quantity}</span>
    </div>
  );
};

SnipcartCounter.propTypes = {
  cartState: PropTypes.shape({
    quantity: PropTypes.number.isRequired,
    hasItems: PropTypes.bool.isRequired,
  }).isRequired,
};

export default SnipcartCounter;
