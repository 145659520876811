/* eslint-disable */

/**
 * Implement Gatsby's Browser APIs in this file.
 * This file is where Gatsby expects to find any usage of the Gatsby browser APIs (if any).
 * These allow customization/extension of default Gatsby settings affecting the browser.
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react';
import Layout from './src/components/Layout';
import Firebase from './src/account/Firebase';
import FirebaseContext from './src/context/FirebaseContext';

require('./src/styles/global.scss');

export const shouldUpdateScroll = ({ routerProps }) => {
  const pathname = routerProps.location.pathname;

  // if page contains a fixed scroll hero component force scroll to top
  // ie. homepage and collections
  if (
    pathname.includes('/collections') ||
    pathname === '/' ||
    pathname.includes('/search')
  ) {
    return [0, 0];
  }
  if (
    pathname.includes('/products') ||
    pathname.includes('/type') ||
    pathname.includes('/resources') ||
    (pathname.includes('/contact-us') && routerProps.location.hash !== '')
  ) {
    return false;
  }

  return true;
};

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return (
    <FirebaseContext.Provider value={new Firebase()}>
      <Layout {...props}>{element}</Layout>
    </FirebaseContext.Provider>
  );
};

export const onClientEntry = () => {
  if (!('IntersectionObserver' in window)) {
    return import('intersection-observer');
  }
};
