import AuthUserContext from '@context/AuthUserContext';
import FirebaseContext from '@context/FirebaseContext';
import ProjectsContext from '@context/ProjectsContext';
import { navigate } from '@reach/router';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

/* eslint-disable no-console */
const ProjectsProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [repsLoading, setRepsLoading] = useState(true);
  const [projects, setProjects] = useState(null);
  const [reps, setReps] = useState(null);
  const [snapshotError, setSnapshotError] = useState('');
  const firebase = useContext(FirebaseContext);
  const { authUser } = useContext(AuthUserContext);

  // Query Projects
  useEffect(() => {
    const unsubscribe = authUser
      ? onSnapshot(
          query(
            collection(firebase.db, 'projects'),
            where('roles.owners', 'array-contains', `${authUser.uid}`),
            orderBy('updated', 'desc'),
          ),
          (snapshot) => {
            if (snapshot.size) {
              setLoading(false);
              const projectDataArray = [];
              snapshot.forEach((doc) =>
                projectDataArray.push({ ...doc.data(), id: doc.id }),
              );
              // filter projects that have been archived from projects state
              const removeArchivedProjects = projectDataArray.filter(
                (proj) => proj.archived === false,
              );
              setProjects(removeArchivedProjects);
            } else {
              setLoading(false);
            }
          },
          (error) => {
            // console.log('Error querying projects:', error);
            setSnapshotError(error.message);
          },
        )
      : () => null;
    return () => {
      unsubscribe();
      setProjects(null);
      setLoading(true);
      setSnapshotError('');
    };
  }, [authUser, firebase.db]);

  // Query Sales Reps, performs a get which queries once
  // unlike the realtime listener onSnapshot in Projects
  useEffect(() => {
    if (authUser) {
      const getReps = async () => {
        try {
          const docs = await getDocs(
            query(
              collection(firebase.db, 'sales_reps'),
              where('users', 'array-contains', `${authUser.uid}`),
            ),
          );
          if (docs.size) {
            const salesReps = [];
            docs.forEach((doc) =>
              salesReps.push({ ...doc.data(), id: doc.id }),
            );
            setReps(salesReps);
          }
          setRepsLoading(false);
        } catch (error) {
          // console.log('Error fetching sales reps: ', error);
        }
      };
      getReps();
    }
    return () => {
      setReps(null);
      setRepsLoading(true);
    };
  }, [authUser, firebase.db]);

  const getCurrentProject = (projectId) => {
    if (projects) {
      return projects.filter((proj) => proj.id === projectId);
    }
  };

  const createProject = (projectName, products, onSuccess) => {
    // add() will auto generate a project id
    let updatedProducts = {}
    Object.keys(products).map((k)=> {
      updatedProducts[k.replace("0.1", "0_1")] = products[k]
    })
    addDoc(collection(firebase.db, 'projects'), {
      name: projectName,
      roles: {
        owners: [firebase.auth.currentUser.uid],
      },
      products: { ...updatedProducts },
      archived: false,
      created: serverTimestamp(),
      updated: serverTimestamp(),
    })
      .then(() => {
        onSuccess();
      })
      .catch((error) => {
        console.error('Error writing project: ', error);
      });
  };

  const updateProject = (projectId, projectData) => {
    // update() will update the passed data within the document
    // without overwriting the whole document
    const projectRef = doc(collection(firebase.db, 'projects'), projectId);
    return updateDoc(projectRef, {
      ...projectData,
      updated: serverTimestamp(),
    }).catch((error) => {
      console.error('Error updating project: ', error);
    });
  };

  const updateProjectProduct = (projectId, productSku, quantity, slug) => {
    // update() will update the passed data within the document
    // without overwriting the whole document
    const projectRef = doc(collection(firebase.db, 'projects'), projectId);
    return getDoc(projectRef)
      .then((doc) => {
        if (doc.exists()) {
          productSku = productSku.replace("0.1", "0_1");
          // add existing product quantity if it exists
          if (doc.data()?.products[productSku]?.quantity) {
            const sumQuanity =
              doc.data()?.products[productSku]?.quantity + quantity;
            return updateDoc(projectRef, {
              [`products.${productSku}`]: {
                id: productSku,
                quantity: sumQuanity,
                slug,
              },
              updated: serverTimestamp(),
            });
          }
          // else add new product
          return updateDoc(projectRef, {
            [`products.${productSku}`]: {
              id: productSku,
              quantity,
              slug,
            },
            updated: serverTimestamp(),
          });
        }
      })
      .catch((error) => {
        console.error('Error updating project product: ', error);
      });
  };

  const deleteProject = (projectId) => {
    // soft delete project with archive flag
    const projectRef = doc(collection(firebase.db, 'projects'), projectId);
    return updateDoc(projectRef, {
      archived: true,
      deleted: serverTimestamp(),
    }).then(() => {
      navigate('/account/projects');
    });
  };

  return (
    <ProjectsContext.Provider
      value={{
        loading,
        projects,
        snapshotError,
        reps,
        repsLoading,
        getCurrentProject: (projectId) => getCurrentProject(projectId),
        createProject: (projectName, products, onSuccess) =>
          createProject(projectName, products, onSuccess),
        deleteProject: (projectId) => deleteProject(projectId),
        updateProject: (projectId, projectData) =>
          updateProject(projectId, projectData),
        updateProjectProduct: (projectId, productSku, quantity, slug) =>
          updateProjectProduct(projectId, productSku, quantity, slug),
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
};

ProjectsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProjectsProvider;
