import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '../../../static/images/icons/icon-close.svg';
import * as styles from './styles';
import cn from 'classnames';

const ButtonClose = ({ screenReader, white, ...other }) => (
  <button
    type="button"
    className={cn(styles.closeIcon, {
      [styles.white]: white,
    })}
    {...other}
  >
    <span className="sr-only">{screenReader}</span>
    <img src={CloseIcon} alt="Close icon" />
  </button>
);

ButtonClose.propTypes = {
  screenReader: PropTypes.string,
  white: PropTypes.bool,
};

ButtonClose.defaultProps = {
  screenReader: 'Close',
  white: false,
};

export default ButtonClose;
