import Button from '@components/Button';
import { AccountIcon, CartIcon, SearchIcon } from '@components/Icons';
import Logo from '@components/Logo';
import SnipcartCounter from '@components/SnipCart/counter';
import AuthUserContext from '@context/AuthUserContext';
import NavbarContext from '@src/context/NavbarContextOld';
import bodyScroll from '@helpers/bodyScroll';
import { useLocation } from '@reach/router';
import Link from '@utility/Link';
import ScrollInView from '@utility/ScrollInView/index';
import useNavItems from '@utility/useNavItems';
import cn from 'classnames';
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Portal } from 'react-portal';
import * as styles from './styles';

const MobileNav = React.memo(({ cartState }) => {
  const { authUser } = useContext(AuthUserContext);
  const { show, navRef } = useContext(NavbarContext);
  const overlayRef = useRef();
  const navContainerRef = useRef();
  const navListRef = useRef();
  const location = useLocation();
  const navItems = useNavItems();

  const [navOpen, setNavOpen] = useState(false);
  const [subNavOpen, setSubNavOpen] = useState(false);
  const [bannerHeight, setBannerHeight] = useState(0);

  const subNavScrollTop = () => {
    if (overlayRef.current) {
      overlayRef.current.scrollTo({
        top: 0,
      });
    }
  };

  const onClickHandler = () => {
    if (navOpen) {
      bodyScroll.unlock();
    } else {
      bodyScroll.lock();
    }
    setSubNavOpen(false);
    subNavScrollTop();

    setNavOpen(!navOpen);
  };

  useEffect(() => {
    setNavOpen(false);
    setSubNavOpen(false);
    subNavScrollTop();
    bodyScroll.unlock();
  }, [location]);

  useLayoutEffect(() => {
    const MutationObserver =
      window.MutationObserver || window.WebKitMutationObserver;
    const observer = new MutationObserver(() => {
      const stonlyBanner = document.querySelector('.stonly-banner-wrapper');

      if (stonlyBanner) {
        setBannerHeight(stonlyBanner.offsetHeight);
      } else {
        setBannerHeight(0);
      }
    });

    observer.observe(document, {
      subtree: true,
      childList: true,
    });

    return () => {
      observer.disconnect();
    };
  }, [navOpen]);

  useLayoutEffect(() => {
    if (navRef.current && navListRef.current) {
      if (bannerHeight) {
        if (
          navOpen &&
          +document.body.style.top.slice(0, -2) > -1 * bannerHeight
        ) {
          document.body.style.marginTop = '0';
          navRef.current.style.top = `${bannerHeight}px`;
          navListRef.current.style.paddingTop = `${bannerHeight}px`;
        } else {
          document.body.style.marginTop = `${bannerHeight}px`;
          navRef.current.style.top = '';
          navListRef.current.style.paddingTop = '';
        }
      } else {
        document.body.style.marginTop = '';
        navRef.current.style.top = '';
        navListRef.current.style.paddingTop = '';
      }
    }
  }, [bannerHeight, navOpen]);

  useLayoutEffect(() => {
    if (navRef.current) {
      if (navOpen) {
        show();
        navRef.current.classList.add('!translate-y-0');
      } else {
        navRef.current.classList.remove('!translate-y-0');
      }
    }
  }, [navOpen]);

  return (
    <div className="wrapper lg:hidden">
      <div className={cn(styles.navigation, 'row', 'no-transition')}>
        <div
          className={cn(
            'col-12',
            styles.navigationContainer,
            {
              'is-theme-light': navOpen,
            },
            {
              [styles.navigationContainerOpen]: navOpen,
            },
            {
              [styles.navigationContainerSubNavOpen]: subNavOpen,
            },
          )}
          ref={navContainerRef}
        >
          <ul className={cn(styles.navigationList, 'relative z-30 w-full')}>
            <li
              className={cn(
                styles.navigationItem,
                styles.navigationItemHeader,
                'flex-grow',
              )}
            >
              <Link
                to="/"
                styled={false}
                title="RBW Home Page"
                aria-label="RBW Home Page"
              >
                <Logo
                  className={cn(
                    styles.navigationLogo,
                    'rbwNavLogo transition-[height] duration-500 ease-in',
                    'no-transition',
                  )}
                  width="44"
                  height="47"
                  aria-label="RBW Logo"
                />
              </Link>
              <Button
                icon="chevron-left-black-small"
                iconLeft
                onClick={() => {
                  subNavScrollTop();
                  setSubNavOpen(false);
                }}
                aria-label="Browse Products"
                className={styles.navigationBack}
              >
                Products
              </Button>
            </li>
            <li className={cn(styles.navigationItem, styles.navigationIcon)}>
              <Button className="snipcart-checkout" title="Cart">
                <CartIcon />
                <span className="sr-only">Cart</span>
              </Button>
              <SnipcartCounter cartState={cartState} />
            </li>
            <li className={cn(styles.navigationItem, styles.navigationIcon)}>
              <Link
                to="/search"
                styled={false}
                title="Search"
                className={styles.navigationItemCircle}
                activeClassName={styles.navigationItemCircleActive}
                partiallyActive
                onClick={() => {
                  location.pathname === '/search' &&
                    window &&
                    window.history.go(-1);
                }}
                aria-label="Search"
              >
                <SearchIcon />
                <span className="sr-only">Search</span>
              </Link>
            </li>
            <button
              className={cn(styles.navigationMenu, {
                [styles.navigationMenuActive]: navOpen,
              })}
              aria-label="Navigation Menu"
              onClick={() => onClickHandler()}
              type="button"
            >
              <span className={styles.navigationMenuBox}>
                <span className={styles.navigationMenuInner} />
              </span>
            </button>
          </ul>
          <Portal
            node={
              typeof window !== 'undefined'
                ? document?.querySelector('main')
                : null
            }
          >
            <div
              className={cn(
                styles.mobileOverlay,
                {
                  [styles.mobileOverlayOpen]: navOpen,
                },
                'is-theme-light',
              )}
              ref={navListRef}
            >
              <div
                className={cn(styles.mobileOverlayContainer, {
                  [styles.mobileOverlayContainerOpen]: subNavOpen,
                })}
              >
                <div className="wrapper">
                  <div className="row">
                    <ScrollInView
                      as="ul"
                      animateType="fadeInUp"
                      className="col-12 has-delay-container"
                    >
                      {/* archiving 'products' popup as per https://www.wrike.com/open.htm?id=1094081926 */}
                      {/* <li
                        className={cn(
                          styles.navigationItemMobile,
                          styles.navigationSubNavButton,
                          'has-delay',
                        )}
                      >
                        <Button
                          icon="chevron-right-black"
                          onClick={() => {
                            subNavScrollTop();
                            setSubNavOpen(true);
                          }}
                        >
                          {
                            navItems?.allSanityNavigation?.edges[0]?.node
                              ?.navigationDropdownLabel
                          }
                        </Button>
                      </li> */}
                      {navItems &&
                        navItems?.allSanityNavigation?.edges[0]?.node?._rawNavigationTopLevel.map(
                          (item, index) => (
                            <li
                              className={cn(
                                styles.navigationItemMobile,
                                'has-delay',
                                index === 0
                                  ? styles.navigationSubNavButton
                                  : '',
                              )}
                              key={item._key}
                            >
                              <Link
                                to={item}
                                internalExternal
                                styled={false}
                                aria-label={item.label}
                              >
                                {item.label}
                              </Link>
                            </li>
                          ),
                        )}
                      <li className="has-delay">
                        <Link
                          to="/account/projects"
                          styled={false}
                          className="pt-12 flex items-center -ml-2"
                          aria-label={authUser ? 'My Profile' : 'Login'}
                        >
                          <AccountIcon />
                          <span className="type-upper-140">
                            {authUser ? 'My Profile' : 'Login'}
                          </span>
                        </Link>
                      </li>
                    </ScrollInView>
                  </div>
                </div>
                {/* archiving 'products' popup as per https://www.wrike.com/open.htm?id=1094081926 */}
                {/* <div
                  className={cn('wrapper', styles.mobileOverlaySub)}
                  ref={overlayRef}
                >
                  <div className="row">
                    <ScrollInView
                      as="ul"
                      animateType="fadeInUp"
                      className={cn('col-12', styles.mobileOverlaySubList)}
                    >
                      <ul>
                        <li className="type-upper-140 mb-4">Type</li>
                        {navItems &&
                          navItems.allSanityNavigation.edges[0].node
                            .navigationType &&
                          navItems.allSanityNavigation.edges[0].node.navigationType.map(
                            (type) => (
                              <li key={type._key}>
                                <Link
                                  to={`${type.path}`}
                                  activeClassName="text-black"
                                  styled={false}
                                  className={styles.navigationItemMobileSub}
                                  aria-label={type.label}
                                >
                                  {type.label}
                                </Link>
                              </li>
                            ),
                          )}
                      </ul>
                      <ul className="pb-12">
                        <li className="type-upper-140 mb-4 mt-16 has-delay">
                          Collection
                        </li>
                        {navItems &&
                          navItems.allSanityNavigation.edges[0].node
                            .navigationCollection &&
                          navItems.allSanityNavigation.edges[0].node.navigationCollection.map(
                            (collection) => (
                              <li key={collection._key} className="has-delay">
                                <Link
                                  to={`/${collection.url.slug.current}`}
                                  activeClassName="text-black"
                                  styled={false}
                                  className={styles.navigationItemMobileSub}
                                  aria-label={collection.label}
                                >
                                  {collection.label}
                                </Link>
                              </li>
                            ),
                          )}
                      </ul>
                    </ScrollInView>
                  </div>
                </div> */}
              </div>
            </div>
          </Portal>
        </div>
      </div>
    </div>
  );
});

export default MobileNav;
