import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from '@reach/router';
import cn from 'classnames';
import bodyScroll from '@helpers/bodyScroll';
import imageUrlFor from '@helpers/imageUrlFor';
import useNavItems from '@utility/useNavItems';
import ScrollInView from '@utility/ScrollInView/index';
import Link from '@utility/Link/index';
import ButtonClose from '@components/ButtonClose';
import * as styles from './styles';
import { Portal } from 'react-portal';

const Overlay = React.memo(() => {
  const location = useLocation();
  const navItems = useNavItems();
  const ref = useRef();
  // Collection image sizes
  const cs = [
    { w: 354, h: 506 },
    { w: 780, h: 1170 },
  ];
  // Type image sizes
  const ts = [
    { w: 354, h: 506 },
    { w: 780, h: 468 },
    { w: 780, h: 950 },
  ];
  const buildImageUrl = (i, data, type) =>
    imageUrlFor(data).width(type[i].w).height(type[i].h).fit('crop').url();
  const [overlayHeight, setOverlayHeight] = useState('0');
  const [reverse, setReverse] = useState(
    navItems.allSanityNavigation.edges[0].node.navigationType[0].reverse,
  );
  const defaultImages = [
    {
      img: buildImageUrl(
        0,
        navItems.allSanityNavigation.edges[0].node.navigationType[0].images[0]
          .image,
        ts,
      ),
      label:
        navItems.allSanityNavigation.edges[0].node.navigationType[0].images[0]
          .label,
    },
    {
      img: buildImageUrl(
        1,
        navItems.allSanityNavigation.edges[0].node.navigationType[0].images[1]
          .image,
        ts,
      ),
      label:
        navItems.allSanityNavigation.edges[0].node.navigationType[0].images[1]
          .label,
    },
    {
      img: buildImageUrl(
        2,
        navItems.allSanityNavigation.edges[0].node.navigationType[0].images[2]
          .image,
        ts,
      ),
      label:
        navItems.allSanityNavigation.edges[0].node.navigationType[0].images[2]
          .label,
    },
  ];
  const [heightTransitionComplete, setHeightTransitionComplete] =
    useState(false);
  const [hoverImages, setHoverImages] = useState(defaultImages);
  const [hoveredLink, setHoveredLink] = useState('type');
  const [activeCategory, setactiveCategory] = useState('type');
  const [animateIn, setAnimateIn] = useState(false);
  const [activeFirstLink, setActiveFirstLink] = useState(true);
  const [prevLocation, setPrevLocation] = useState(location.href);
  const scrollIntoViewWithPadding = () => {
    const el = document.getElementById('js-collection');
    const pos = ref.current.scrollTop + el.getBoundingClientRect().top;
    setactiveCategory('collection');
    ref.current.scrollTo({
      top: pos - 32,
      behavior: 'smooth',
    });
  };

  const renderImages = (data, imgArr, cat) => {
    setHoverImages(imgArr);
    setAnimateIn(false);
    setTimeout(() => setAnimateIn(true), 100);
    setHoveredLink(cat);
    if (data.reverse && data.reverse === true) {
      setReverse(true);
    } else {
      setReverse(false);
    }
  };

  const closeNavigation = () => {
    setOverlayHeight('0');
    setHeightTransitionComplete(false);
    bodyScroll.unlock();
  };

  useEffect(() => {
    if (overlayHeight === '0') {
      bodyScroll.unlock();
      setHeightTransitionComplete(false);
      setAnimateIn(false);
    } else {
      setTimeout(() => {
        bodyScroll.lock();
        setHeightTransitionComplete(true);
      }, 500);
      setAnimateIn(false);
      setActiveFirstLink(true);
      setHoverImages(defaultImages);
      setTimeout(() => setAnimateIn(true), 300);
    }
  }, [overlayHeight]);

  return (
    <>
      <button
        type="button"
        onClick={() => setOverlayHeight('100%')}
        className="no-underline-link"
      >
        {navItems?.allSanityNavigation?.edges[0]?.node?.navigationDropdownLabel}
      </button>
      <Portal>
        <ul
          className={`${styles.overlay} is-theme-light`}
          style={{ height: overlayHeight }}
        >
          <div
            className={cn(styles.close, {
              [styles.closeActive]: overlayHeight === '100%',
            })}
          >
            <ButtonClose
              screenReader="Close Navigation"
              onClick={() => setOverlayHeight('0')}
              aria-label="Close Navigation"
            />
          </div>
          <div
            className={cn(styles.container, {
              'overflow-y-scroll': heightTransitionComplete,
            })}
            ref={ref}
          >
            <div className="wrapper">
              <div className="row">
                <div className="offset-5 col-7">
                  <ScrollInView
                    delayself="0.6s"
                    animateType="fadeIn"
                    key={overlayHeight}
                    className={cn(
                      styles.imageContainer,
                      `is-active-${hoveredLink}`,
                      {
                        [styles.imageContainerSticky]: overlayHeight === '100%',
                      },
                    )}
                  >
                    <div
                      className={cn(
                        styles.imageContainerInner,
                        { [styles.imageContainerInnerAnimateIn]: animateIn },
                        {
                          [styles.imageContainerInnerReverse]: reverse,
                        },
                      )}
                    >
                      <div className={styles.image}>
                        <div className={styles.imageInner}>
                          <figure>
                            <img src={hoverImages[0].img} alt="" />
                            <figcaption>{hoverImages[0].label}</figcaption>
                          </figure>
                        </div>
                      </div>
                      <div className={styles.image}>
                        <div className={styles.imageInner}>
                          <figure>
                            <img src={hoverImages[1].img} alt="" />
                            <figcaption>{hoverImages[1].label}</figcaption>
                          </figure>
                        </div>
                      </div>
                      <div className={styles.image}>
                        <div className={styles.imageInner}>
                          <figure>
                            <img
                              src={hoverImages[2] && hoverImages[2].img}
                              alt=""
                            />
                            <figcaption>
                              {hoverImages[2] && hoverImages[2].label}
                            </figcaption>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </ScrollInView>
                </div>
              </div>
              <div className="row">
                <ul className="col-12">
                  <ScrollInView
                    as="ul"
                    delayself="0.4s"
                    className="mt-20 mb-8 flex"
                    animateType="fadeIn"
                    key={overlayHeight}
                  >
                    <li>
                      <button
                        type="button"
                        className={cn(styles.category, {
                          'text-black': activeCategory === 'type',
                        })}
                        aria-label="Type"
                        onClick={() => setactiveCategory('type')}
                      >
                        Type
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className={cn(styles.category, {
                          'text-black': activeCategory === 'collection',
                        })}
                        onClick={() => scrollIntoViewWithPadding()}
                      >
                        Collection
                      </button>
                    </li>
                  </ScrollInView>
                  {navItems &&
                    navItems.allSanityNavigation.edges[0].node.navigationType &&
                    navItems.allSanityNavigation.edges[0].node.navigationType.map(
                      (type, i) => (
                        <li key={type._key}>
                          <ScrollInView
                            key={overlayHeight}
                            animateType="fadeInUp"
                            delayself="0.6s"
                          >
                            <Link
                              to={`${type.path}`}
                              className={cn(styles.link, {
                                [styles.linkActive]: activeFirstLink && i === 0,
                                'text-mono-500':
                                  type.path ===
                                  `${location.pathname}${location.search}`,
                              })}
                              aria-label={type.label}
                              styled={false}
                              onClick={closeNavigation}
                              onMouseEnter={() => {
                                setActiveFirstLink(false);
                                return renderImages(
                                  type,
                                  [
                                    {
                                      img: buildImageUrl(
                                        0,
                                        type.images[0].image,
                                        ts,
                                      ),
                                      label: type.images[0].label,
                                    },
                                    {
                                      img: buildImageUrl(
                                        1,
                                        type.images[1].image,
                                        ts,
                                      ),
                                      label: type.images[1].label,
                                    },
                                    {
                                      img: buildImageUrl(
                                        2,
                                        type.images[2].image,
                                        ts,
                                      ),
                                      label: type.images[2].label,
                                    },
                                  ],
                                  'type',
                                );
                              }}
                            >
                              {type.label}
                            </Link>
                          </ScrollInView>
                        </li>
                      ),
                    )}
                  <li id="js-collection" className="mt-20 mb-8">
                    <button
                      type="button"
                      className={cn(styles.category, styles.categoryLine, {
                        'text-black': activeCategory === 'collection',
                      })}
                      onClick={() => scrollIntoViewWithPadding()}
                      aria-label='Collection'
                    >
                      <ScrollInView
                        key={overlayHeight}
                        animateType="fadeInUp"
                        className={styles.line}
                        delayself="0.5s"
                      >
                        Collection
                      </ScrollInView>
                    </button>
                  </li>
                  {navItems &&
                    navItems.allSanityNavigation.edges[0].node
                      .navigationCollection &&
                    navItems.allSanityNavigation.edges[0].node.navigationCollection.map(
                      (collection) => (
                        <li key={collection._key}>
                          <ScrollInView
                            key={overlayHeight}
                            animateType="fadeInUp"
                          >
                            <Link
                              to={`/${collection.url.slug.current}`}
                              activeClassName="text-mono-500"
                              className={styles.link}
                              styled={false}
                              aria-labe={collection.label}
                              onClick={closeNavigation}
                              onMouseEnter={() => {
                                setActiveFirstLink(false);
                                return renderImages(
                                  collection,
                                  [
                                    {
                                      img: buildImageUrl(
                                        0,
                                        collection.images[0].image,
                                        cs,
                                      ),
                                      label: collection.images[0].label,
                                    },
                                    {
                                      img: buildImageUrl(
                                        1,
                                        collection.images[1].image,
                                        cs,
                                      ),
                                      label: collection.images[1].label,
                                    },
                                    {
                                      // transparent pixel
                                      img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
                                      label: '',
                                    },
                                  ],
                                  'collection',
                                );
                              }}
                            >
                              {collection.label}
                            </Link>
                          </ScrollInView>
                        </li>
                      ),
                    )}
                </ul>
              </div>
            </div>
          </div>
        </ul>
      </Portal>
    </>
  );
});

Overlay.displayName = 'Overlay';

export default Overlay;
