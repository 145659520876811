import { useEffect, useState } from 'react';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config'; // Fix the path

const fullConfig = resolveConfig(tailwindConfig);

export const getBreakpointValue = (value) => {
  if (typeof fullConfig.theme.screens[value] === 'string') {
    return +fullConfig.theme.screens[value].slice(
      0,
      fullConfig.theme.screens[value].indexOf('px'),
    );
  } else {
    return 0;
  }
};

export const useCurrentBreakpoint = () => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState(null);

  useEffect(() => {
    const getCurrentBreakpoint = () => {
      let biggestBreakpointValue = 0;

      for (const breakpoint of Object.keys(fullConfig.theme.screens)) {
        const breakpointValue = getBreakpointValue(breakpoint);
        if (
          breakpointValue > biggestBreakpointValue &&
          window.innerWidth >= breakpointValue
        ) {
          biggestBreakpointValue = breakpointValue;
          setCurrentBreakpoint(breakpoint);
        }
      }
    };

    getCurrentBreakpoint();

    window.addEventListener('resize', getCurrentBreakpoint);

    return () => {
      window.removeEventListener('resize', getCurrentBreakpoint);
    };
  }, []);

  return currentBreakpoint;
};
