import { useCurrentBreakpoint } from '@helpers/currentBreakpoint';
import { useLocation } from '@reach/router';
import useCartState from '@utility/useCartState';
import useScrollPosition from '@utility/useScrollPosition';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  NORMAL_NAV_HEIGHT_DESKTOP,
  NORMAL_NAV_HEIGHT_MOBILE,
  SMALL_NAV_HEIGHT_DESKTOP,
  SMALL_NAV_HEIGHT_MOBILE,
} from '../../common';

const NavbarContext = React.createContext({
  show: () => {},
  hide: () => {},
  isVisible: true,
  navRef: null,
});

export default NavbarContext;

export function NavbarContextProvider({ children }) {
  const [isVisible, setIsVisible] = useState(true);

  const { quantity } = useCartState();
  const currentBreakpoint = useCurrentBreakpoint();
  const location = useLocation();

  //* references the main navbar wrapper
  const navRef = useRef();

  const locationPath = useRef(null);
  const timer = useRef(null);

  const show = useCallback(() => {
    setIsVisible(true);
  }, []);

  const hide = useCallback(() => {
    setIsVisible(false);
  }, []);

  const scrollFn = ({ prevPos, currPos }) => {
    //* Navbar show and hide on scroll functionality
    if (
      (currPos > 150 &&
        (currPos - prevPos > 0 ||
          (locationPath.current !== location.pathname && !isVisible))) ||
      !!document.documentElement.style.position
    ) {
      location.pathname === '/' ? show() : hide();
    } else {
      show();
    }

    //* Navbar height and navbar logo change on scroll functionality
    if (!document.documentElement.style.position) {
      if (currPos <= 100) {
        document.body.style.setProperty(
          '--nav-height-desktop',
          `${NORMAL_NAV_HEIGHT_DESKTOP}px`,
        );
        document.body.style.setProperty(
          '--nav-height-mobile',
          `${NORMAL_NAV_HEIGHT_MOBILE}px`,
        );

        document.querySelectorAll('.rbwNavLogo').forEach((logo) => {
          logo.setAttribute(
            'height',
            `${
              currentBreakpoint === 'lg' || currentBreakpoint === 'xl' ? 62 : 47
            }`,
          );
        });
      } else if (currPos > 100) {
        document.body.style.setProperty(
          '--nav-height-desktop',
          `${SMALL_NAV_HEIGHT_DESKTOP}px`,
        );
        document.body.style.setProperty(
          '--nav-height-mobile',
          `${SMALL_NAV_HEIGHT_MOBILE}px`,
        );

        document.querySelectorAll('.rbwNavLogo').forEach((logo) => {
          logo.setAttribute(
            'height',
            `${
              currentBreakpoint === 'lg' || currentBreakpoint === 'xl' ? 45 : 40
            }`,
          );
        });
      }
    }

    //* Navbar background color change functionality
    const childNav = navRef.current.firstElementChild;
    if (location.pathname === '/') {
      if (currPos > 150) {
        childNav.classList.add('bg-light', 'text-black');
        childNav.classList.remove('text-white', 'bg-transparent', 'border-p');
      } else {
        childNav.classList.remove('bg-light', 'text-black');
        childNav.classList.add('text-white', 'bg-transparent', 'border-p');
      }
    } else {
      if (currPos > 150) {
        childNav.classList.add('bg-light');
        childNav.classList.remove('bg-transparent');
      } else {
        childNav.classList.remove('bg-light');
        childNav.classList.add('bg-transparent', 'text-black');
      }
    }

    //* Navbar side duration change functionality
    if (currPos > 150) {
      navRef.current.classList.add('duration-700');
      navRef.current.classList.remove('duration-[1200ms]');
    } else {
      navRef.current.classList.remove('duration-700');
      navRef.current.classList.add('duration-[1200ms]');
    }

    // * Navbar Timeout functionality
    if (location.pathname === '/') {
      if (timer.current && currPos <= 150) {
        clearTimeout(timer.current);
      }

      if (isVisible && currPos > 150) {
        if (timer.current) {
          clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
          hide();
        }, 4000);
      }
    }
  };

  useScrollPosition(
    scrollFn,
    [isVisible, currentBreakpoint, location.pathname],
    0,
    true,
  );

  //* route change state setter
  useLayoutEffect(() => {
    const noTransitionEls = document.querySelectorAll('.no-transition');
    noTransitionEls.forEach((el) => {
      el.classList.add('!duration-0');
    });

    const locationTimer = setTimeout(() => {
      locationPath.current = location.pathname;

      noTransitionEls.forEach((el) => {
        el.classList.remove('!duration-0');
      });
    }, 500);

    return () => {
      clearTimeout(locationTimer);
    };
  }, [location.pathname]);

  //* show on cart quantity change
  useEffect(() => {
    setIsVisible(true);
  }, [quantity]);

  //* navbar slide in and out functionality
  useLayoutEffect(() => {
    if (navRef.current) {
      if (isVisible) {
        navRef.current.classList.add('translate-y-0');
        navRef.current.classList.add('ease-out');
        navRef.current.classList.remove('-translate-y-full');
        navRef.current.classList.remove('ease-in');
      } else {
        navRef.current.classList.add('-translate-y-full');
        navRef.current.classList.add('ease-in');
        navRef.current.classList.remove('translate-y-0');
        navRef.current.classList.remove('ease-out');
      }
    }
  }, [isVisible]);

  return (
    <NavbarContext.Provider value={{ show, hide, isVisible, navRef }}>
      {children}
    </NavbarContext.Provider>
  );
}
