/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import Cursor from '@components/Cursor';
import Footer from '@components/Footer';
import SnipCart from '@components/SnipCart';
import AuthUserContext from '@context/AuthUserContext';
import { NavbarContextProvider } from '@src/context/NavbarContextOld';
import { ThemeProvider } from '@context/ThemeContext';
import isSafariBrowser from '@helpers/isSafari';
import useCartState from '@utility/useCartState';
import useIsomorphicLayoutEffect from '@utility/useIsomorphicLayoutEffect';
import PropTypes from 'prop-types';
import React, { useContext, useLayoutEffect, useState } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import withAuthentication from '../account/Auth/withAuthentication';
import ProjectsProvider from '../account/Projects/ProjectsProvider';
import Navigation from './Navigation';

const Layout = ({ children }) => {
  const { authUser } = useContext(AuthUserContext);
  const cartState = useCartState();
  const [isSafari, setIsSafari] = useState(true);
  useIsomorphicLayoutEffect(() => {
    if (window.zE && authUser) {
      zE('webWidget', 'prefill', {
        name: {
          value:
            authUser.first_name && authUser.last_name
              ? authUser.first_name + ' ' + authUser.last_name
              : '',
          readOnly: false,
        },
        email: {
          value: authUser.email ? authUser.email : '',
          readOnly: false,
        },
      });
    }
  }, [authUser]);
  useLayoutEffect(() => {
    if (isSafariBrowser() !== null) {
      setIsSafari(isSafariBrowser());
    }
  }, []);
  const renderChildren = () => (
    <>
      <Navigation cartState={cartState} />
      <main>
        {children}
        {/* <Cursor /> */}
      </main>
      <Footer />
      <SnipCart />
    </>
  );
  return (
    <ProjectsProvider>
      <ThemeProvider>
        <NavbarContextProvider>
          {isSafari ? (
            renderChildren()
          ) : (
            <ParallaxProvider>{renderChildren()}</ParallaxProvider>
          )}
        </NavbarContextProvider>
      </ThemeProvider>
    </ProjectsProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withAuthentication(Layout);
