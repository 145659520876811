import isEmpty from 'lodash.isempty';
import {
  PRODUCT_IMAGE_URL_PREFIX,
  DOWNLOAD_IES,
  DOWNLOAD_REVIT,
  DOWNLOAD_DRAWINGS,
  DOWNLOAD_TECHPACK,
  DOWNLOAD_SKETCHUP,
  DOWNLOAD_INSTALLINSTRUCTIONS,
  DOWNLOAD_OTHER,
  REGEX_STRINGS_EXCLUDE_HYPHEN_UNDERSCORE,
  PRODUCT_VIDEO_URL_PREFIX,
} from '../../common';

const getProductSlugfromPrefix = (slugPrefix) => {
  return slugPrefix.replace('products/', '');
};

export const attributesFromSkuSuffix = (skuSuffix, limit = null) => {
  let attributes = skuSuffix.split('-');

  if (limit) {
    return attributes.slice(0, limit);
  }

  return attributes;
};

export const getDefaultMediaSrc = (
  slugPrefix,
  type,
  partialSku,
  attributes,
  imageWidth,
  fileType = 'jpg',
) => {
  if (!isVideoType(fileType) || !fileType || attributes.length > 0) {
    // => media is of type image
    return productImageSrc(
      slugPrefix,
      type,
      partialSku,
      attributes,
      imageWidth,
      (fileType = 'jpg'),
    );
  } else if (isVideoType(fileType)) {
    // => media if of type video
    return productVideoSrc(slugPrefix, type, partialSku, fileType);
  }
};

export const isVideoType = (fileType) => {
  return ['mov', 'mp4'].includes(fileType?.toLowerCase());
};

export const productImageSrc = (
  slugPrefix,
  type,
  partialSku,
  attributes,
  imageWidth,
  fileType = 'jpg',
) => {
  // prettier-ignore
  const srcPrefix = `${PRODUCT_IMAGE_URL_PREFIX}products/${getProductSlugfromPrefix(slugPrefix)}/${type}/${partialSku}`;
  let src = `${srcPrefix}_default.${fileType}${
    imageWidth ? `?w=${imageWidth}` : ''
  }`;

  if (attributes?.length > 0) {
    const srcAttributes = attributes
      .map((attr) => {
        return `_${attr}`;
      })
      .join('');

    return `${srcPrefix + srcAttributes}.${fileType}${
      imageWidth && fileType !== 'gif' ? `?w=${imageWidth}` : ''
    }`;
  }

  return src;
};

export const productVideoSrc = (slugPrefix, type, partialSku, fileType) => {
  let src = `${PRODUCT_VIDEO_URL_PREFIX}products/${getProductSlugfromPrefix(
    slugPrefix,
  )}/${type}/${partialSku}_default.${fileType}`;

  return src;
};

export const getAvailableImageUrl = (baseSrc, format) => {
  if (!format) format = 'gif';
  let src1 = baseSrc.split('_')[0]; //To replace everything after '_', with the hover image extension, so that it works even when finishes present in URL.
  return src1 + `_hover.${format}`;
};

export const calcLeadTimeByQuantity = (quantity, data) => {
  let {
    minLeadTime,
    maxLeadTime,
    leadTime10,
    leadTime25,
    leadTime50,
    leadTime100,
    leadTime200,
  } = data;
  const diff = maxLeadTime - minLeadTime;
  if (Number.isNaN(quantity)) {
    return { min: minLeadTime, max: maxLeadTime };
  }

  if (quantity >= 0 && quantity <= 3) {
    return { min: minLeadTime, max: minLeadTime + diff };
  }

  if (quantity >= 4 && quantity <= 10)
    return getMinMax(leadTime10 ?? minLeadTime, diff);
  if (quantity >= 11 && quantity <= 25)
    return getMinMax(leadTime25 ?? minLeadTime, diff);
  if (quantity >= 26 && quantity <= 50)
    return getMinMax(leadTime50 ?? minLeadTime, diff);
  if (quantity >= 51 && quantity <= 100)
    return getMinMax(leadTime100 ?? minLeadTime, diff);
  if (quantity >= 101 && quantity <= 200)
    return getMinMax(leadTime200 ?? minLeadTime, diff);

  return { min: minLeadTime, max: maxLeadTime }; // Quantity > 200
};

const getMinMax = (min, diff) => ({ min: min, max: min + diff });

export const productDownloadNames = (attribute) => {
  let name = '';
  switch (attribute) {
    case 'ies':
      name = DOWNLOAD_IES;
      break;
    case 'revit':
      name = DOWNLOAD_REVIT;
      break;
    case 'drawings':
      name = DOWNLOAD_DRAWINGS;
      break;
    case 'techPack':
      name = DOWNLOAD_TECHPACK;
      break;
    case 'sketchUp':
      name = DOWNLOAD_SKETCHUP;
      break;
    case 'installInstructions':
      name = DOWNLOAD_INSTALLINSTRUCTIONS;
      break;
    case 'other':
    case 'other2':
      name = DOWNLOAD_OTHER;
      break;
    default:
      name = '';
  }
  return name;
};

export const productDownloads = (downloads) => {
  const customOrder = [
    'drawings',
    'revit',
    'sketchUp',
    'ies',
    'techPack',
    'installInstructions',
    'other',
    'other2',
  ];

  if (!isEmpty(downloads)) {
    const orderedDownloads = {};
    customOrder.map((type) => (orderedDownloads[type] = downloads[type]));

    const modifiedArr = Object.entries(orderedDownloads)
      .filter(([key, value]) => key !== '_type' && !!value)
      .map(([key, value]) => {
        if (key === 'other' || key === 'other2') {
          return {
            title: value.title || 'Untitled',
            file: value.link || '',
            type: 'other',
          };
        } else {
          return {
            title: productDownloadNames(key),
            file: value,
          };
        }
      });

    // Separate 'other' types and move them to the end of the array
    const otherTypes = modifiedArr.filter((el) => el.type === 'other');
    const nonOtherTypes = modifiedArr.filter((el) => el.type !== 'other');

    return [...nonOtherTypes, ...otherTypes];
  }

  return [];
};

export const blankSkuSuffixHtml = (suffix) => {
  return suffix.replace(
    REGEX_STRINGS_EXCLUDE_HYPHEN_UNDERSCORE,
    '<span class="inline-block w-10 print:w-6 print:!h-[1rem] print:!leading-6 bg-mono-200">&nbsp;</span>',
  );
};

export const extractDomain = (url) => {
  url = url.replace(/(^\w+:|^)\/\//, '');
  url = url.replace('www.', '');

  if (url.charAt(url.length - 1) === '/') {
    url = url.slice(0, -1);
  }

  return url;
};
