const tailwindFlexGrid = require('./tailwind-plugins/tailwind-flex-grid');
const tailwindTypography = require('./tailwind-plugins/tailwind-typography');
const tailwindUtilities = require('./tailwind-plugins/tailwind-utilities');

module.exports = {
  content: [
    './src/**/*.{html,js,jsx,ts,tsx}',
    './static/**/*.{html,js,jsx,ts,tsx}',
  ],
  theme: {
    colors: {
      transparent: 'transparent',
      red: '#FF3366',
      pink: '#ed3d67',
      lightPink: '#fdf6f7',
      current: 'currentColor',
      black: 'var(--color-black)',
      white: 'var(--color-white)',
      light: 'var(--color-light)',
      mono: {
        100: 'var(--color-mono-100)',
        200: 'var(--color-mono-200)',
        300: 'var(--color-mono-300)',
        400: 'var(--color-mono-400)',
        500: 'var(--color-mono-500)',
        600: 'var(--color-mono-600)',
      },
      green: {
        DEFAULT: 'var(--color-green)',
      },
    },
    fontFamily: {
      sans: ['Replica Std', 'Helvetica Neue', 'sans-serif'],
    },
    screens: {
      xs: '350px',
      'sm-down': { max: '575px' },
      sm: '576px',
      'md-down': { max: '767px' },
      md: '768px',
      'lg-down': { max: '991px' },
      lg: '992px',
      'xl-down': { max: '1199px' },
      xl: '1200px',
      '2xl': '2000px'
    },
    extend: {
      screens: {
        print: { raw: 'print' },
      },
      spacing: {
        7: '1.75rem',
        9: '2.25rem',
        10: '2.5rem',
        13: '3.25rem',
        14: '3.5rem',
        15: '3.75rem',
        18: '4.5rem',
        28: '7rem',
        30: '7.5rem',
      },
      zIndex: {
        70: '70',
        60: '60',
        '-1': -1,
      },
      borderRadius: {
        px: '1px',
      },
      maxHeight: {
        0: '0rem',
      },
      maxWidth: {
        '3xs': '4.5rem',
        '2xs': '16rem',
        screen: 'var(--max-width)',
      },
      transitionTimingFunction: {
        'ease-in-custom': 'cubic-bezier(0.32, 0.24, 0.15, 1)',
        'in-expo': 'cubic-bezier(0.7, 0, 0.84, 0)',
      },
      transitionDuration: {
        0: '0ms',
      },
      margin: {
        'm-1/2': '0.125',
      },
      minHeight: {
        48: '12rem',
      },
      height: {
        /**==============================================
         *  These values are the default heights of the
         *  navbars in desktop and mobile respectively.
         *
         *  See ./common.js for the more info.
         *=============================================**/
        172: '172px',
        120: '120px',
      },
    },
    maxWidth: (theme, { breakpoints }) => ({
      none: 'none',
      '3xs': '4.5rem',
      xs: '20rem',
      sm: '24rem',
      md: '28rem',
      lg: '32rem',
      xl: '36rem',
      '2xl': '42rem',
      '3xl': '48rem',
      '4xl': '56rem',
      '5xl': '64rem',
      '6xl': '72rem',
      full: '100%',
      ...breakpoints(theme('screens')),
    }),
  },
  plugins: [
    tailwindFlexGrid({
      columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      gutters: [16, 36],
      breakpoint: 768,
      maxWidth: 1280,
    }),
    tailwindTypography(),
    tailwindUtilities(),
  ],
};
