import { useStaticQuery, graphql } from 'gatsby';

const useNavItems = () => {
  const navItems = useStaticQuery(
    graphql`
      query {
        allSanityNavigation {
          edges {
            node {
              _rawNavigationTopLevel(resolveReferences: { maxDepth: 10 })
              navigationDropdownLabel
              navigationCollection {
                label
                _key
                reverse
                images {
                  label
                  image {
                    asset {
                      _id
                    }
                  }
                }
                url {
                  id
                  slug {
                    current
                  }
                }
              }
              navigationType {
                label
                _key
                path
                reverse
                images {
                  label
                  image {
                    asset {
                      _id
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  );
  return navItems;
};

export default useNavItems;
