import React from 'react';
import PropTypes from 'prop-types';

const Logo = React.memo(({ className, height = 86, width = 74 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="10 10 44 52"
    width={width}
    height={height}
    className={className}
  >
    <switch>
      <g className="fill-current" fill="#000">
        <path d="M18.4,47.8H16l-0.1-0.1v-2.4l0.1-0.1h2.4c1,0,1.5,0.4,1.5,1.3C19.9,47.4,19.4,47.8,18.4,47.8 M15.8,41.1l0.1-0.1h2.4 c0.4,0,0.7,0.1,0.9,0.3c0.2,0.2,0.3,0.5,0.3,0.9c0,0.3-0.1,0.6-0.3,0.9c-0.2,0.2-0.5,0.3-0.9,0.3H16l-0.1-0.1V41.1z M20.2,44.2 v-0.1c0.5-0.1,0.9-0.4,1.1-0.8c0.2-0.4,0.4-0.9,0.4-1.4c0-0.9-0.3-1.6-0.9-2c-0.6-0.4-1.3-0.7-2.1-0.7h-4.8l-0.1,0.1v10.2l0.1,0.1 H19c0.8,0,1.5-0.2,2.1-0.7c0.6-0.5,0.9-1.2,0.9-2.2C22,45.4,21.4,44.5,20.2,44.2" />
        <polygon points="52.1,39.1 50.6,39.1 50.5,39.3 49,46.2 48.8,46.2 46.9,39.3 46.7,39.1 44.8,39.1 44.6,39.3 42.7,46.2 42.5,46.2 41,39.3 40.9,39.1 39.4,39.1 39.2,39.3 39.2,40.8 41.5,49.5 41.6,49.6 43.6,49.6 43.7,49.5 45.7,42.3 45.8,42.3 47.8,49.5  47.9,49.6 49.9,49.6 50,49.5 52.3,40.8 52.3,39.3 	" />
        <path d="M33.1,18.3c-0.3,0.2-0.6,0.3-1.1,0.3h-2.8L29,18.4V16l0.1-0.1H32c0.5,0,0.9,0.1,1.1,0.3c0.3,0.2,0.4,0.5,0.4,1 C33.5,17.7,33.4,18.1,33.1,18.3 M33.4,20.2c0.6-0.2,1.2-0.5,1.6-1c0.4-0.5,0.6-1.2,0.6-2c0-1-0.3-1.8-1-2.3 c-0.7-0.5-1.5-0.8-2.6-0.8h-4.9l-0.1,0.1v10.2l0.1,0.1h1.8l0.2-0.1v-3.9l0.1-0.2h1.5l0.3,0.2l3,3.9l0.3,0.2h0.9l0.1-0.1v-1.5 l-1.9-2.5L33.4,20.2z" />
        <polygon points="52.5,23.5 52.2,23.6 31,35.3 9.9,23.6 9.6,23.5 9.4,23.5 9.4,25.3 30.1,36.8 30.1,60.3 31.1,60.3 32,60.3 32,36.8 52.7,25.3 52.7,23.5" />
      </g>
    </switch>
  </svg>
));

Logo.displayName = 'Logo';

Logo.propTypes = {
  className: PropTypes.string,
};

Logo.defaultProps = {
  className: '',
};

export default Logo;
