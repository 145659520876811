import { useState, useEffect, useLayoutEffect } from 'react';
import isEmpty from 'lodash.isempty';

const useCartState = () => {
  const [quantity, setQuantity] = useState(0);
  const [cartState, setCartState] = useState({ items: { items: [] } });
  const [hasItems, setHasItems] = useState(false);

  const getQuantity = (state) => {
    let count = 0;

    state.cart.items.items.map((item) => {
      count += item.quantity;
    });

    return count;
  };

  useLayoutEffect(() => {
    // console.log('run');
    document.addEventListener('snipcart.ready', () => {
      if (window?.Snipcart?.events) {
        window.Snipcart.events.on('snipcart.initialized', (snipcartState) => {
          // console.log('Snipcart: Initialized');
          setCartState(snipcartState.cart);
          setQuantity(getQuantity(snipcartState));
        });

        const events = ['item.added', 'item.removed', 'item.updated'];

        events.map((event) => {
          window.Snipcart.events.on(event, (e) => {
            // console.log('Snipcart: ', event, e);
            const state = window.Snipcart.store.getState();
            setCartState(state.cart);
            setQuantity(getQuantity(state));
          });
          return null;
        });
      }
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(cartState)) {
      // console.log('Snipcart: Set items');
      setHasItems(cartState.items && cartState.items.items.length > 0);
      // console.log(cartState);
    }
  }, [cartState]);

  return { quantity, hasItems, cartState };
};

export default useCartState;
