import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

const ThemeContext = React.createContext({
  dark: false,
  semiDark: false,
  toggle: () => {},
  isDark: () => {},
  isSemiDark: () => {},
});

export default ThemeContext;

export function ThemeProvider({ children }) {
  const [dark, setDark] = useState(false);
  const [semiDark, setSemiDark] = useState(false);

  const toggle = () => {
    setDark(!dark);
  };

  const isDark = (val) => {
    setDark(val);
  };

  const isSemiDark = (val) => {
    setSemiDark(val);
  };

  useLayoutEffect(() => {
    if (dark) {
      document.body.classList.add('is-theme-dark');
    } else {
      document.body.classList.remove('is-theme-dark');
    }
  }, [dark]);

  useLayoutEffect(() => {
    if (semiDark) {
      document.body.classList.add('is-theme-semi-dark');
    } else {
      document.body.classList.remove('is-theme-semi-dark');
    }
  }, [semiDark]);

  return (
    <ThemeContext.Provider
      value={{ dark, semiDark, toggle, isDark, isSemiDark }}
    >
      {children}
    </ThemeContext.Provider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
