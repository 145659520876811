import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import cn from 'classnames';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import * as styles from './styles';
import { SANITY_CONFIG } from '../../../common';

const FluidImage = ({
  src,
  maxWidth,
  maxHeight,
  mobileRatio,
  objectFit,
  quality,
  observer,
  style,
  className,
  loading,
  aspectRatio,
  ...other
}) => {
  const [animate, setAnimation] = useState(false);
  const [imageProps, setImageProps] = useState(null);

  if (!src || !src.asset || !src.asset._id) {
    return null;
  }

  const mobilefluidProps = getGatsbyImageData(
    src.asset._id,
    {
      maxWidth: 1534,
      maxHeight,
      objectFit,
      quality,
      aspectRatio,
    },
    SANITY_CONFIG,
  );

  const fluidProps = getGatsbyImageData(
    src.asset._id,
    {
      maxWidth,
      maxHeight,
      objectFit,
      quality,
      ...(aspectRatio ||
        (maxWidth && maxHeight ? { aspectRatio: maxWidth / maxHeight } : {})),
    },
    SANITY_CONFIG,
  );

  useEffect(() => {
    const generatedFluidProps = () => {
      if (mobileRatio && window?.innerWidth <= 767) {
        setImageProps({
          ...mobilefluidProps,
          aspectRatio: mobileRatio,
        });
      } else {
        setImageProps(fluidProps);
      }
    };

    generatedFluidProps();

    window.addEventListener('resize', generatedFluidProps);

    return () => window.removeEventListener('resize', generatedFluidProps);
  }, []);

  return imageProps ? (
    <GatsbyImage
      image={imageProps}
      alt={src.altText || ''}
      className={
        observer
          ? className
          : cn(styles.image, className, { [styles.animated]: animate })
      }
      onLoad={() => setAnimation(true)}
      objectFit={objectFit}
      loading={loading}
      imgStyle={style}
      {...other}
    />
  ) : null;
};

FluidImage.propTypes = {
  src: PropTypes.shape({
    asset: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }).isRequired,
    altText: PropTypes.string,
  }).isRequired,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  mobileRatio: PropTypes.number,
  objectFit: PropTypes.oneOf(['cover', 'contain', 'fill', 'none']),
  quality: PropTypes.number,
  observer: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  loading: PropTypes.oneOf(['eager', 'lazy', 'auto']),
  aspectRatio: PropTypes.number,
};

FluidImage.defaultProps = {
  maxWidth: 1280,
  maxHeight: null,
  mobileRatio: null,
  objectFit: 'cover',
  quality: 50,
  observer: true,
  style: {},
  className: '',
  loading: 'lazy',
  aspectRatio: null,
};

export default FluidImage;
