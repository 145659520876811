import React from 'react';
import PropTypes from 'prop-types';

import FormInput from '@utility/FormInput';

const FormHoneypot = ({ register }) => (
  <FormInput
    type="text"
    className="sr-only-no-focus"
    {...register('honeypot', {
      required: false,
      validate: (value) => value === '',
    })}
  />
);

FormHoneypot.propTypes = {
  register: PropTypes.func.isRequired,
};

export default FormHoneypot;