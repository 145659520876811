import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import * as styles from './styles';

const ScrollInView = ({
  as,
  children,
  className,
  delayself,
  triggerOnce,
  animateType,
  skip,
  ...other
}) => {
  const [ref, inView] = useInView({
    /* Optional options */
    triggerOnce,
    rootMargin: '75px',
  });
  const Wrapper = as;
  return (
    <Wrapper
      ref={ref}
      className={`${inView && !skip ? styles.isInView : ''} ${
        styles[animateType]
      } ${className}`}
      delayself={delayself}
      style={{ transitionDelay: `${delayself},${delayself}` }}
      {...other}
    >
      {children}
    </Wrapper>
  );
};

ScrollInView.propTypes = {
  className: PropTypes.string,
  delayself: PropTypes.string,
  children: PropTypes.node.isRequired,
  triggerOnce: PropTypes.bool,
  animateType: PropTypes.oneOf(['translateInUp', 'fadeInUp', 'fadeIn']),
  as: PropTypes.string,
};

ScrollInView.defaultProps = {
  as: 'div',
  className: '',
  delayself: '0s',
  triggerOnce: true,
  animateType: 'translateInUp',
};

export default React.memo(ScrollInView);
