import priceFormat from './priceFormat';

export const viewItemListDL = () => {
  // if (!products.length) return;
  // const items = products.map((hit) => ({
  //   item_id: getProductCode(hit),
  //   item_name: hit.title,
  //   item_category:
  //     hit.collection?.length > 0 ? hit.collection[0]?.title?.title : '', //collection name
  //   item_variant: hit.productFinish?.finish?.category?.title,
  //   price: priceFormat(hit.price / 100),
  //   quantity: 1,
  // }));

  // @ts-ignore
  dataLayer.push({ ecommerce: null });
  // @ts-ignore
  dataLayer.push({
    event: 'rbw_view_item_list',
    ecommerce: {
      item_list_id: 'related_products',
      item_list_name: 'Related products',
      items: [],
    },
  });
};

export const viewItemDL = (productDetails) => {
  let productOptions = {};
  if (productDetails.skuSuffix === 'Accessory') {
    productOptions.item_category = 'Accessory'
  } else {
    productOptions = getProductCategories(
      productDetails.skuSuffix.split('-'),
    );
  }

  // @ts-ignore
  dataLayer.push({ ecommerce: null });
  // @ts-ignore
  dataLayer.push({
    event: 'rbw_view_item',
    ecommerce: {
      currency: 'USD',
      value: productDetails.price / 100,
      items: [
        {
          item_id: productDetails.productCode,
          item_name: productDetails.name,
          item_variant: productDetails.variant,
          price: productDetails.price / 100,
          quantity: 1,
          ...productOptions,
        },
      ],
    },
  });
};

export const addToCartDL = (item) => {
  const productOptions = item.url.split('/').pop().split('-');
  const categories = getProductCategories(productOptions);

  // @ts-ignore
  dataLayer.push({ ecommerce: null });
  // @ts-ignore
  dataLayer.push({
    event: 'rbw_add_to_cart',
    ecommerce: {
      currency: 'USD',
      value: item.totalPrice,
      items: [
        {
          item_id: item.id,
          item_name: item.name,
          item_variant: '',
          price: item.price,
          quantity: item.quantity,
          ...categories,
        },
      ],
    },
  });
};

export const removeFromCartDL = (item) => {
  const productOptions = item.url.split('/').pop().split('-');
  const categories = getProductCategories(productOptions);

  // @ts-ignore
  dataLayer.push({ ecommerce: null });
  // @ts-ignore
  dataLayer.push({
    event: 'rbw_remove_from_cart',
    ecommerce: {
      currency: 'USD',
      value: item.totalPrice,
      items: [
        {
          item_id: item.id,
          item_name: item.name,
          item_variant: '',
          price: item.price,
          quantity: item.quantity,
          ...categories,
        },
      ],
    },
  });
};

export const beginCheckoutDL = (items, totalPrice) => {
  // @ts-ignore
  dataLayer.push({ ecommerce: null });
  // @ts-ignore
  dataLayer.push({
    event: 'rbw_begin_checkout',
    ecommerce: {
      currency: 'USD',
      value: totalPrice,
      items,
    },
  });
};

export const viewCartDL = (items, totalPrice) => {
  // @ts-ignore
  dataLayer.push({
    event: 'rbw_view_cart',
    ecommerce: {
      currency: 'USD',
      value: totalPrice,
      items,
    },
  });
};

export const purchaseCompleteDL = (items, totalPrice, orderDetails, userDetails) => {
  // @ts-ignore
  dataLayer.push({ ecommerce: null });
  // @ts-ignore
  dataLayer.push({
    event: 'rbw_purchase',
    ecommerce: {
      currency: 'USD',
      value: totalPrice,
      items,
      ...orderDetails,
    },
    customer: userDetails
  });
};

export const searchDL = (keyword) => {
  // @ts-ignore
  dataLayer.push({
    event: 'rbw_search',
    search_terms: keyword,
  });
};

export const signUpSuccessDL = (email) => {
  // @ts-ignore
  dataLayer.push({
    event: 'rbw_signup',
    user_email: email,
  });
};

export const newsletterSubscribeDL = (email) => {
  // @ts-ignore
  dataLayer.push({
    event: 'rbw_newsletter_signup',
    user_email: email,
  });
};

const getProductCode = (product) => {
  let code = product.partial_sku;
  let suffix = product.slug.current.split('/')[2];
  return (code + '-' + suffix).toUpperCase();
};

export const getProductCategories = (productOptionArray) => {
  //map productOptionList to add the values for categories item_category, item_category2...
  const categories = productOptionArray.reduce((acc, item, index) => {
    acc[`item_category${index < 1 ? '' : index + 1}`] = item.toUpperCase();
    return acc;
  }, {});

  return categories;
};
