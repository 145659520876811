import NavbarContext from '@src/context/NavbarContextOld';
import ThemeContext from '@context/ThemeContext';
import { useLocation } from '@reach/router';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import DesktopNav from './desktop';
import MobileNav from './mobile';

const Navigation = ({ cartState }) => {
  const { navRef } = useContext(NavbarContext);
  const { dark, semiDark } = useContext(ThemeContext);
  const location = useLocation();

  return (
    <div
      className={cn(
        'w-full sticky inset-x-0 top-0 z-60 transition-transform duration-[1200ms] translate-y-0 print:hidden bg-transparent',
        'h-100 md:h-120',
        'no-transition',
      )}
      ref={navRef}
    >
      <nav
        className={cn(
          'w-full transition-[background-color] ease-in-expo duration-500',
          {
            '!bg-light': !(dark || semiDark) && location.pathname !== '/',
            'text-white': location.pathname === '/',
            'text-black': location.pathname !== '',
            'border-p': location.pathname === '/'
          },
        )}
      >
        <MobileNav cartState={cartState} />
        <DesktopNav cartState={cartState} />
      </nav>
    </div>
  );
};

Navigation.propTypes = {
  cartState: PropTypes.shape({
    quantity: PropTypes.number.isRequired,
    hasItems: PropTypes.bool.isRequired,
  }),
};

export default Navigation;
