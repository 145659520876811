/* eslint-disable */

module.exports = function () {
  return ({ addUtilities, theme }) => {
    const newUtilities = {
      '.animate-in-up-start': {
        'will-change': 'transform',
        transform: 'translate3d(0, 60px, 0)',
        transition: 'transform 2s cubic-bezier(.16,1.08,.38,.98)',
      },
      '.animate-in-up-end': {
        transform: 'translate3d(0, 0, 0)',
      },
      '.animate-fade-in-up-start': {
        'will-change': 'transform, opacity',
        transform: 'translate3d(0, 60px, 0)',
        opacity: '0',
        transition:
          'transform 2s cubic-bezier(.16,1.08,.38,.98), opacity ease-in 0.5s',
      },
      '.animate-fade-in-up-end': {
        transform: 'translate3d(0, 0, 0)',
        opacity: '1',
      },
      '.animate-in': {
        'will-change': 'opacity',
        transition: 'opacity 0.3s ease-in',
      },
      '.animate-fade-in-start': {
        'will-change': 'opacity',
        opacity: '0',
        transition: 'opacity ease-in 0.5s',
      },
      '.animate-fade-in-end': {
        opacity: '1',
      },
      '.inline-link': {
        'text-decoration': 'none',
        cursor: 'pointer',
        position: 'relative',
        minHeight: '24px',
        display: 'inline-block',
        width: 'fit-content',
        'will-change': 'opacity',
        transition: 'opacity 0.3s ease-out, border 0.3s ease-out',
        '&:after': {
          content: '""',
          'background-repeat': 'no-repeat',
          position: 'absolute',
          width: '100%',
          height: '85%',
          top: '0',
          left: '0',
          'z-index': '-1',
          'background-image': `linear-gradient(transparent calc(101% - 1px),${theme(
            'colors.black',
          )} 1px)`,
          'background-size': '100% 100%',
        },
        '&:hover, &:focus': {
          opacity: '0.5',
        },
      },
      '.no-underline-link': {
        'text-decoration': 'none',
        cursor: 'pointer',
        position: 'relative',
        minHeight: '24px',
        display: 'inline-block',
        width: 'fit-content',
        '&:after': {
          content: '""',
          'background-repeat': 'no-repeat',
          position: 'absolute',
          width: '100%',
          height: '95%',
          top: '0',
          left: '0',
          'z-index': '-1',
          'background-image': `linear-gradient(transparent calc(100% - 1px),${theme(
            'colors.black',
          )} 1px)`,
          'background-size': '0% 100%',
          transition: 'background-size .6s cubic-bezier(.77,0,.175,1)',
        },
        '&:hover, &:focus': {
          outline: 'none',
        },
        '&:hover:after, &:focus:after': {
          'background-size': '100% 100%',
        },
      },
      '.webkit-sticky': {
        // required for safari webkit
        position: '-webkit-sticky',
      },
      '.translate-z-0': {
        '-webkit-transform': 'translateZ(0)',
        transform: 'translateZ(0)',
      },
    };
    addUtilities(newUtilities, ['responsive']);
  };
};
