/**
 * Type style generator
 * @param {string} suffix - suffix for the tw class name, fontweight will be generated by this
 * @param {object} baseFont - base fontfamily and other consistent style properties
 * @param {number} fontSize - fontSize in px
 * @param {number} lineHeight - lineHeight in px
 * @param {number} letterSpacing - letterSpacing in px
 */
function generateTypeStyle(
  suffix = '',
  baseFont = {},
  fontSize,
  lineHeight,
  letterSpacing,
) {
  return [
    {
      [`${suffix}`]: {
        ...baseFont,
        fontWeight: `${suffix[suffix.length - 2]}00 !important`,
        fontSize: `${fontSize / 16}rem`,
        letterSpacing: `${letterSpacing / 16}rem`,
        lineHeight: `${Math.round((lineHeight / fontSize) * 100) / 100}`,
      },
    },
  ];
}

module.exports = function typeGenerator() {
  return ({ theme, addUtilities }) => {
    const prefixSans = '.type-sans-';
    const prefixUpper = '.type-upper-';
    const baseSans = {
      fontFamily: `${theme('fontFamily.sans')}`,
    };
    const baseUpper = {
      textTransform: 'uppercase',
      fontFamily: `${theme('fontFamily.sans')}`,
    };
    const typeSansStyles = [
      ...generateTypeStyle(`${prefixSans}042`, baseSans, 9, 20, 0.3),
      ...generateTypeStyle(`${prefixSans}030`, baseSans, 12, 20, 0.3),
      ...generateTypeStyle(`${prefixSans}040`, baseSans, 12, 20, 0.3),
      ...generateTypeStyle(`${prefixSans}130`, baseSans, 13, 20, 0.3),
      ...generateTypeStyle(`${prefixSans}140`, baseSans, 13, 20, 0.3),
      ...generateTypeStyle(`${prefixSans}230`, baseSans, 14, 24, 0.3),
      ...generateTypeStyle(`${prefixSans}240`, baseSans, 14, 24, 0.3),
      ...generateTypeStyle(`${prefixSans}330`, baseSans, 16, 26, 0.3),
      ...generateTypeStyle(`${prefixSans}430`, baseSans, 18, 32, 0.4),
      ...generateTypeStyle(`${prefixSans}530`, baseSans, 24, 40, 0.5),
      ...generateTypeStyle(`${prefixSans}630`, baseSans, 32, 40, 1),
      ...generateTypeStyle(`${prefixSans}730`, baseSans, 40, 54, 1.25),
      ...generateTypeStyle(`${prefixSans}830`, baseSans, 48, 56, 0),
      ...generateTypeStyle(`${prefixSans}930`, baseSans, 64, 68, -1),
      ...generateTypeStyle(`${prefixSans}1030`, baseSans, 72, 88, -1),
      ...generateTypeStyle(`${prefixSans}1130`, baseSans, 96, 104, -1),
    ];
    const typeUpperStyles = [
      ...generateTypeStyle(`${prefixUpper}072`, baseUpper, 8, 20, 1.4),
      ...generateTypeStyle(`${prefixUpper}040`, baseUpper, 10, 16, 2),
      ...generateTypeStyle(`${prefixUpper}070`, baseUpper, 10, 16, 1.4),
      ...generateTypeStyle(`${prefixUpper}140`, baseUpper, 12, 20, 2),
      ...generateTypeStyle(`${prefixUpper}170`, baseUpper, 12, 20, 2),
      ...generateTypeStyle(`${prefixUpper}240`, baseUpper, 14, 24, 2),
      ...generateTypeStyle(`${prefixUpper}340`, baseUpper, 18, 24, 2),
    ];
    addUtilities([typeSansStyles, typeUpperStyles], ['responsive']);
  };
};
