import React from 'react';
import PropTypes from 'prop-types';
import FluidImage from '@utility/FluidImage/index';

const ColumnLayout = ({
  children,
  heading,
  offset,
  subheading,
  image,
  hideSubhead,
}) => (
  <div className="lg:wrapper">
    <div className="row">
      <div
        className={`col-12 xl:offset-1 ${
          offset ? 'md:col-5 xl:col-4' : 'md:col-6 xl:col-5'
        }`}
      >
        <h1 className="type-sans-830 xl:type-sans-930 mb-8">{heading}</h1>
        {!hideSubhead && (
          <p className="type-sans-330 text-mono-500 mb-8 pr-6">{subheading}</p>
        )}
        {children}
      </div>
      {image && (
        <div
          className={`col-12 md:col-6 xl:col-5 ${offset ? 'md:offset-1' : ''}`}
        >
          <div className="webkit-sticky sticky top-0 md:-mt-12 pt-12">
            <FluidImage src={image} maxWidth={992} loading="eager" />
          </div>
        </div>
      )}
    </div>
  </div>
);

ColumnLayout.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  image: PropTypes.shape({
    asset: PropTypes.shape({
      _id: PropTypes.string,
    }),
  }),
  offset: PropTypes.bool,
  hideSubhead: PropTypes.bool,
};

ColumnLayout.defaultProps = {
  offset: true,
  image: false,
  hideSubhead: false,
};

export default ColumnLayout;
