import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import * as styles from './styles';

const FormInput = React.forwardRef(
  (
    { label, name, tag: Tag, errorMessage, isOptional, className, ...rest },
    ref,
  ) => {
    return (
      <div className={styles.inputGroup} key={name}>
        {label !== '' && (
          <label htmlFor={name} aria-labelledby={name} className={styles.inputLabel}>
            {label}
            {isOptional ? (
              <span className="type-upper-070 text-mono-400 pl-2">
                Optional
              </span>
            ) : (
              ''
            )}
          </label>
        )}
        <Tag
          ref={ref}
          id={name}
          name={name}
          className={cn(styles.input, className, {
            [styles.inputError]: errorMessage,
          })}
          {...rest}
        />
        {errorMessage && (
          <span className={styles.inputErrorMessage}>{errorMessage}</span>
        )}
      </div>
    );
  },
);

FormInput.displayName = 'FormInput';

FormInput.defaultProps = {
  errorMessage: '',
  label: '',
  isOptional: false,
  tag: 'input',
  className: '',
};

FormInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  isOptional: PropTypes.bool,
  tag: PropTypes.string,
  className: PropTypes.string,
};

export default FormInput;