import AuthUserContext from '@context/AuthUserContext';
import PropTypes from 'prop-types';
import React from 'react';
import uuid from 'react-uuid';
import Cookies from 'universal-cookie';
import withFirebase from '../Firebase/withFirebase';

/**
 * withAuthentication - HOC which makes
 * authenticated user available for
 * the components passed to it
 * this wraps layout.js with the AuthUserContext.Provider
 * so authUser is available to the whole app
 * @param {class} Component
 * @return {class} Component WithAuthentication
 */

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: null,
        authHasConnected: false,
      };
    }

    componentDidMount() {
      const { firebase } = this.props;
      const cookies = new Cookies();
      let uid = cookies.get('rbw-user');
      if (!uid) {
        uid = uuid();
        cookies.set('rbw-user', uid);
      }
      // setup onAuthUserListener
      // onAuthUserListener uses firebases
      // onAuthStateChanged which adds an observer for changes to the user's sign-in state.
      this.listener = firebase.onAuthUserListener(
        // onSuccess firebase user is defined
        (authUser) => {
          console.log('AuthUser: ' + JSON.stringify(authUser, null, 2));
          this.setState({
            authUser,
            authHasConnected: true,
          });
          stonlyTrack('identify', authUser.uid);
        },
        // Fallback if user is not defined
        () => {
          this.setState({ authUser: null, authHasConnected: true });
          stonlyTrack('identify', uid);
        },
      );
    }

    componentWillUnmount() {
      // remove onAuthUserListener on unmount
      this.listener();
    }

    setRefreshedUser(refreshedUser) {
      // refresh user state
      this.setState({
        authUser: refreshedUser,
      });
    }

    render() {
      const { authUser, authHasConnected } = this.state;
      return (
        <AuthUserContext.Provider
          value={{
            authUser,
            authHasConnected,
            setRefreshedUser: (val) => this.setRefreshedUser(val),
          }}
        >
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  WithAuthentication.propTypes = {
    firebase: PropTypes.shape({
      onAuthUserListener: PropTypes.func,
    }).isRequired,
  };

  return withFirebase(WithAuthentication);
};

export default withAuthentication;
