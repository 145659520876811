module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"headers":{"/*":["X-Frame-Options: DENY","X-XSS-Protection: 1; mode=block","X-Content-Type-Options: nosniff","Referrer-Policy: same-origin"],"/products/*":["Access-Control-Allow-Origin: http://localhost:8002"],"/accessories/*":["Access-Control-Allow-Origin: http://localhost:8002"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
