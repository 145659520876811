import React from 'react';

export const AccountIcon = React.memo(() => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.454 16C20.41 16 22 17.736 22 19.871V24h-1.45v-4.129c0-1.281-.94-2.323-2.096-2.323h-4.908c-1.157 0-2.097 1.042-2.097 2.323V24H10v-4.129C10 17.736 11.59 16 13.546 16h4.908zm-2.488-8a3.5 3.5 0 110 7 3.5 3.5 0 010-7zm0 1.502a2.001 2.001 0 00-1.998 1.999 2 2 0 001.998 1.997 2 2 0 002-1.997 2.001 2.001 0 00-2-2z"
      fill="#2C2B28"
      fillRule="evenodd"
      className="fill-current"
    />
  </svg>
));

export const SearchIcon = React.memo(() => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.83 10.83a6.249 6.249 0 018.839 0 6.25 6.25 0 01.5 8.277l3.519 3.52-1.06 1.06-3.52-3.52a6.25 6.25 0 01-8.277-9.336m1.06 1.06a4.75 4.75 0 106.717 6.718 4.75 4.75 0 00-6.716-6.717"
      fill="#2C2B28"
      fillRule="evenodd"
      className="fill-current"
    />
  </svg>
));

export const CartIcon = React.memo(() => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 8c2.466 0 4.472 2.044 4.472 4.556l-.001.497H24v7.958C24 22.109 23.104 23 22 23H11c-1.104 0-2-.891-2-1.99v-7.956l3.028-.001v-.497C12.029 10.044 14.036 8 16.5 8zm6 6.546l-1.529-.001v1.493h-1.5v-1.493h-5.943v1.493h-1.5v-1.493H10.5v6.466a.5.5 0 00.5.497h11a.5.5 0 00.5-.497v-6.465zm-6-5.097c-1.638 0-2.971 1.395-2.971 3.107l-.001.497h5.943v-.497c0-1.712-1.332-3.107-2.971-3.107z"
      fill="#2C2B28"
      fillRule="evenodd"
      className="fill-current"
    />
  </svg>
));
