export const NORMAL_NAV_HEIGHT_DESKTOP = 120;
export const NORMAL_NAV_HEIGHT_MOBILE = 100;
export const SMALL_NAV_HEIGHT_DESKTOP = 90;
export const SMALL_NAV_HEIGHT_MOBILE = 60;

export const SANITY_CONFIG = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_PROJECT_DATASET,
};

export const PRODUCTS_HITS_PER_PAGE = 18;
export const DEFAULT_TYPE_LABEL = 'Any Luminaire';
export const DEFAULT_FINISH_LABEL = 'Any Finish';
export const DEFAULT_COLLECTION_LABEL = 'Any Collection';
export const REQUIRED_FIELD = 'This field is required';
export const EMAIL_ERROR_MESSAGE =
  'Hmmm… that doesn’t look like an email address.';
export const EMAIL_FIELD_PATTERN = /^\S+@\S+$/i;
export const REGEX_CUSTOM_FINISH_PATTERN = /(PCXX|AAXX|PFXX|CX)/i;
export const REGEX_TRAILING_SLASH = /\/+$/;
export const REGEX_STRINGS_EXCLUDE_HYPHEN_UNDERSCORE = /[^-_]+/g;
export const CREATE_ACCOUNT_USER_ROLES = [
  'Interior Designer / Architect',
  'Lighting Designer',
  'Project Manager / Contractor',
  'Press',
];

export const CREATE_ACCOUNT_USER_ROLES_TRADE = [
  'Interior Designer / Architect',
  'Lighting Designer',
  'Project Manager / Contractor',
];

export const PROJECT_APPLICATION_TYPES = [
  'Residential',
  'Hospitality (Amenity Space, Restaurant, Retail)',
  'Hospitality (Education / Healthcare)',
  'Hospitality (Guest Unit / Corridor)',
  'Multi-Family (Amenity Space)',
  'Multi-Family (Guest Unit / Corridor)',
  'Workplace',
];
export const BLOG_POST_PER_PAGE = 8;
export const PRODUCT_IMAGE_URL_PREFIX = 'https://rbw.imgix.net/';
export const PRODUCT_VIDEO_URL_PREFIX =
  'https://rbwpdpimages.s3.us-east-2.amazonaws.com/';
export const CONFIGURATOR_BUTTON_STYLE = {
  0: 'disabled',
  1: 'enabled',
  2: 'selected',
};
export const DOWNLOAD_IES = 'Photometry';
export const DOWNLOAD_REVIT = 'Revit';
export const DOWNLOAD_DRAWINGS = 'DWG';
export const DOWNLOAD_TECHPACK = 'Tech Pack';
export const DOWNLOAD_SKETCHUP = 'Sketch Up';
export const DOWNLOAD_INSTALLINSTRUCTIONS = 'Install Instructions';
export const DOWNLOAD_OTHER = 'Other';

export const TOOLTIP_IP = {
  __html:
    'Ingress Protection rates the degree of protection against dust and water.',
};
export const TOOLTIP_CERTIFICATION = {
  __html:
    'Safety certifications and regulations for our catalog of luminaires.',
};
export const TOOLTIP_DIMMING = {
  __html: 'Dimming control options for our catalog of luminaires.',
};

export const TOOLTIP_ATTRS_IP = {
  'ip-20': {
    __html: 'Basic luminaire construction.',
  },
  'ip-44': {
    __html:
      'Luminaires protected to  withstand damp settings (Outdoors under covered areas, bathrooms but not splash zones).',
  },
  'ip-65': {
    __html:
      'Luminaires protected to  withstand extended wet settings (Outdoors, bathroom splash zones but not submerged).',
  },
};

export const TOOLTIP_ATTRS_CERTIFICATION = {
  'ada-compliant': {
    __html: 'Wall-mounted luminaires protruding up to 4" from the wall.',
  },
  'ce-european-conformity': {
    __html: "Luminaires that meet the European Union's requirements.",
  },
  'declare-lbc-red-list-approved': {
    __html:
      'Product is in compliance with the requirements of the LBC Challenge. More than 99% of ingredients and residuals disclosed.',
  },
  'ul-underwriter-laboratories': {
    __html:
      'Luminaires that have been tested by UL to nationally recognized safety and sustainability standards.',
  },
};

export const TOOLTIP_ATTRS_INPUT = {
  '120v': {
    __html:
      '100-120V. Input power most commonly used in North, Central America, and Japan.',
  },
  '220v': {
    __html:
      '220-240V. Input power most commonly used in Europe, Asia, Australia and Africa.',
  },
  '277v': {
    __html:
      '110-277V. Universal input power for global applications. Often requires additional control wires for dimming.',
  },
};

export const TOOLTIP_ATTRS_DIMMING = {
  '010v': {
    __html:
      '0-10V is commonly used in high performance requirements such as commercial projects. It requires analog control wires that send signals from 0 to 10 volts to determine dim level. Excellent performance.',
  },
  dali: {
    __html:
      "DALI enables digital control of individual fixtures via a low voltage communication protocol. It's most commonly used in Europe. Excellent performance. Additional signal wires required.",
  },
  'forward-phase': {
    __html:
      'Also known as TRIAC It’s the most commonly installed dimming control system that operates on 120V AC. Works with most common household dimmer switches. Additional control/signal wires not required.',
  },
  'reverse-phase': {
    __html:
      'Also known as ELV (Electronic Low Voltage). Control/signal wires not required.',
  },
};

export const TOOLTIP_ATTRS_FILES = {
  dwg: {
    __html: 'Product line drawings in .DWG',
  },
  revit: {
    __html: 'Revit/Revit Family files in .RFA',
  },
  'sketch-up': {
    __html: '3D models in .SKP',
  },
  photometry: {
    __html: 'Photometry files in .IES and photometry reports in .PDF',
  },
  'tech-pack': {
    __html: 'Product drawings in .PDF',
  },
  'install-instructions': {
    __html: 'Install instructions in  .PDF',
  },
  other: {
    __html: 'Other files',
  },
};
