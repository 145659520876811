const max = require('lodash.max');
const range = require('lodash.range');
const plugin = require('tailwindcss/plugin');

function getOffset(size, columns) {
  const num = size / max(columns);
  if (num === 1) {
    return '0';
  }
  return `${(size / max(columns)) * 100}%`;
}

const transformArray = (arr) => {
  const result = {};
  arr.forEach((item) => {
    Object.keys(item).forEach((key) => {
      result[key] = item[key];
    });
  });
  return result;
};

module.exports = function gridGenerator({
  columns = range(0, 12),
  gutters,
  breakpoint,
  maxWidth,
}) {
  return plugin(function flexGrid({ addComponents }) {
    const flexRules = {
      '.wrapper': {
        maxWidth: `${maxWidth}px`,
        width: '100%',
        margin: '0 auto',
        paddingRight: `${gutters[1] / 2}px`,
        paddingLeft: `${gutters[1] / 2}px`,
      },

      '.negative-wrapper': {
        maxWidth: `${maxWidth}px`,
        width: `calc(100% + 36px)`,
        marginRight: `-${gutters[1] / 2}px`,
        marginLeft: `-${gutters[1] / 2}px`,
      },

      '.negative-wrapper-small': {
        maxWidth: `${maxWidth}px`,
        width: `calc(100% + 28px)`,
        marginRight: `-${gutters[1] / 2}px`,
        marginLeft: `-${gutters[1] / 2}px`,
      },

      '.row': {
        display: 'flex',
        flexWrap: 'wrap',
        marginLeft: `${gutters[0] / -2}px`,
        marginRight: `${gutters[0] / -2}px`,
        [`@media (min-width: ${breakpoint}px)`]: {
          marginRight: `${gutters[1] / -2}px`,
          marginLeft: `${gutters[1] / -2}px`,
        },
      },

      '.col': {
        flexBasis: 0,
        flexGrow: 1,
        maxWidth: '100%',
        paddingRight: `${gutters[0] / 2}px`,
        paddingLeft: `${gutters[0] / 2}px`,
        [`@media (min-width: ${breakpoint}px)`]: {
          paddingRight: `${gutters[1] / 2}px`,
          paddingLeft: `${gutters[1] / 2}px`,
        },
      },

      ...transformArray(
        columns.map((size) => ({
          [`.col-${size}`]: {
            position: 'relative',
            width: '100%',
            minHeight: '1px',
            paddingRight: `${gutters[0] / 2}px`,
            paddingLeft: `${gutters[0] / 2}px`,
            flex: `0 0 ${(size / max(columns)) * 100}%`,
            maxWidth: `${(size / max(columns)) * 100}%`,
            [`@media (min-width: ${breakpoint}px)`]: {
              paddingRight: `${gutters[1] / 2}px`,
              paddingLeft: `${gutters[1] / 2}px`,
            },
          },
        })),
      ),

      ...transformArray(
        columns.map((size) => ({
          [`.offset-${size}`]: {
            marginLeft: getOffset(size, columns),
          },
        })),
      ),
    };

    addComponents({ ...flexRules });
  });
};
