import React from 'react';

const ProjectsContext = React.createContext({
  projects: null,
  loading: true,
  snapshotError: '',
  getCurrentProject: (projectId) => {},
  createProject: (projectName, products, onSuccess) => {},
  updateProject: (projectId, projectData) => {},
  updateProjectProduct: (projectId, productSku, quantity, slug) => {},
  deleteProject: (projectId) => {},
  reps: null,
  repsLoading: false,
});

export default ProjectsContext;
