import imageUrlBuilder from '@sanity/image-url';
import { SANITY_CONFIG } from '../../common';

const builder = imageUrlBuilder({ ...SANITY_CONFIG });

export default function imageUrlFor(source = { asset: {} }) {
  const imageObj = {
    asset: { _ref: source.asset._id || source.asset._ref },
  };

  if (source.crop) imageObj.crop = source.crop;
  if (source.hotspot) imageObj.hotspot = source.hotspot;

  return builder.image(imageObj);
}
