import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const Button = React.forwardRef(
  (
    { className, children, icon, iconLeft, iconClassName, type, ...other },
    ref,
  ) => (
    <button className={className} type={type} {...other} ref={ref}>
      <>
        {iconLeft === false && children}
        {icon && (
          <span
            className={cn('btn-icon', iconClassName, {
              'btn-icon--left': iconLeft,
            })}
            style={{ backgroundImage: `url('/images/icons/icon-${icon}.svg')` }}
          />
        )}
        {iconLeft === true && children}
      </>
    </button>
  ),
);

Button.displayName = 'Button';

Button.propTypes = {
  type: PropTypes.oneOf(['submit', 'button', 'reset']),
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.string,
  iconLeft: PropTypes.bool,
  iconClassName: PropTypes.string,
};

Button.defaultProps = {
  type: 'button',
  children: null,
  className: '',
  icon: '',
  iconLeft: false,
  iconClassName: '',
};

export default React.memo(Button);
