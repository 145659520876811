import Button from '@components/Button';
import FirebaseContext from '@context/FirebaseContext';
import FormHoneypot from '@utility/FormHoneypot';
import FormInput from '@utility/FormInput';
import React, { useContext, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  EMAIL_ERROR_MESSAGE,
  EMAIL_FIELD_PATTERN,
  REQUIRED_FIELD,
} from '../../../common';
import ArrowIcon from '../../../static/images/icons/icon-arrow-right-m.svg';
import * as styles from './styles.module.scss';
import { newsletterSubscribeDL } from '@src/helpers/dataLayerHelper';

const Newsletter = () => {
  const ref = useRef();
  const [isSubmitted, setSubmitted] = useState(false);
  const firebase = useContext(FirebaseContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const directAddHandler = (data) => {
    firebase
      .subscribeKlaviyoNewsletter(data)
      .then((response) => {
        newsletterSubscribeDL(data.emailNewsletter)
        console.log(response);
        setSubmitted(true);
      })
      .catch((err) => console.error(err));
  };

  return (
    <div ref={ref} className="type-sans-030">
      <form
        className={styles.newsletter}
        onSubmit={handleSubmit(directAddHandler)}
      >
        <FormInput
          type="email"
          {...register('emailNewsletter', {
            required: true,
            pattern: EMAIL_FIELD_PATTERN,
          })}
          placeholder="Enter your email"
          label="SIGN UP WITH EMAIL"
          name="emailNewsletter"
          errorMessage={
            errors.emailNewsletter &&
            (errors.emailNewsletter.type === 'required'
              ? REQUIRED_FIELD
              : EMAIL_ERROR_MESSAGE)
          }
          disabled={isSubmitted}
        />
        <FormHoneypot register={register} />
        <Button
          type="submit"
          className={styles.newsletterSubmit}
          disabled={isSubmitted}
          hidden={isSubmitted}
          aria-label="Subscribe to newsletter"
        >
          <span class="visually-hidden">Subscribe</span>
          <img src={ArrowIcon} alt="Right arrow icon" />
        </Button>
      </form>
      {isSubmitted && <p className="-mt-4">Thank you for subscribing!</p>}
    </div>
  );
};

export default Newsletter;
